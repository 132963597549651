import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const InternalContent = makeShortcode("InternalContent");
const Gif = makeShortcode("Gif");
const WistiaVideo = makeShortcode("WistiaVideo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TabPanel data-id="Canvas Libraries" mdxType="TabPanel">
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "canvas-libraries"
        }}>{`Canvas Libraries`}</h2>
        <p>{`Using Canvas will help you to design more efficiently and effectively, while speaking the same
language as your developers. Canvas has been specifically designed to bridge the gap between these
two disciplines by mapping the visual stylings of libraries in Figma with the actual code in
`}<a parentName="p" {...{
            "href": "https://github.com/Workday/canvas-kit"
          }}>{`Canvas Kit`}</a>{` and Canvas Mobile.`}</p>
        <p>{`The `}<a parentName="p" {...{
            "href": "/components/overview"
          }}>{`components`}</a>{` in the official Canvas design libraries and Canvas code repositories
have been created to be as universal, flexible and scalable as possible. This means they can be of
most value to the most amount of teams using them.`}</p>
        <p>{`Components are assembled using Canvas `}<a parentName="p" {...{
            "href": "/styles/tokens/overview"
          }}>{`tokens`}</a>{`, which are consistent preset stylings used
in both Figma design libraries and code libraries alike. Think of these like the DNA that makes
Workday products look and feel like they’re part of the same family.`}</p>
        <h2 {...{
          "id": "supported-design-libraries"
        }}>{`Supported Design Libraries`}</h2>
        <p>{`The Canvas Design System team supports and maintains a number of libraries in Figma. We’ve created a
rule around our libraries: if it exists in our library, it is built in code. This is to help you
design and communicate with your engineers more effectively and ensure you feel confident that
whatever you design can be built in code.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/18a11d90782dc6ba7c9704da39b6a146/d9ed5/canvas-figma-library.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "53.24324324324324%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Canvas Figma Libraries",
                "title": "Canvas Figma Libraries",
                "src": "/static/18a11d90782dc6ba7c9704da39b6a146/50383/canvas-figma-library.png",
                "srcSet": ["/static/18a11d90782dc6ba7c9704da39b6a146/1efb2/canvas-figma-library.png 370w", "/static/18a11d90782dc6ba7c9704da39b6a146/50383/canvas-figma-library.png 740w", "/static/18a11d90782dc6ba7c9704da39b6a146/d9ed5/canvas-figma-library.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "enabling-libraries"
        }}>{`Enabling Libraries`}</h2>
        <p>{`When starting to design with Design System elements, simply toggle on the appropriate Canvas
Libraries in Figma. Toggling on these libraries will allow you to search for and use design system
elements with ease.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9e9d08e6427e6fb42385edee9c00a3fe/d9ed5/enabling-libraries.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "63.24324324324324%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Enabling Libraries",
                "title": "Enabling Libraries",
                "src": "/static/9e9d08e6427e6fb42385edee9c00a3fe/50383/enabling-libraries.png",
                "srcSet": ["/static/9e9d08e6427e6fb42385edee9c00a3fe/1efb2/enabling-libraries.png 370w", "/static/9e9d08e6427e6fb42385edee9c00a3fe/50383/enabling-libraries.png 740w", "/static/9e9d08e6427e6fb42385edee9c00a3fe/d9ed5/enabling-libraries.png 2880w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "upgrading-canvas-libraries"
        }}>{`Upgrading Canvas Libraries`}</h2>
        <p>{`Canvas versioning improves communication between design and development teams during collaboration
and handover, allowing all parties to work in sync. The process enables Figma users to upgrade their
Figma files to a newer version of the Canvas Web Figma library when they need it through an
automated process that only takes a few minutes to complete.`}</p>
        <p>{`Before upgrading, it is recommended to review `}<a parentName="p" {...{
            "href": "/help/upgrade-guides"
          }}>{`the updates`}</a>{` made in each
version to be informed about any changes and appropriate actions can be taken to amend a working
file. Designers and developers on the same team should be using the same version of Canvas. discuss
this before upgrading to a newer version of the Canvas Web Figma library, and vice versa, to align
on what version the team will be building with.`}</p>
        <p>{`From there, making the upgrade is simple. Follow the steps outlined below to perform the upgrade
using
`}<a parentName="p" {...{
            "href": "https://help.figma.com/hc/en-us/articles/4404856784663-Swap-style-and-component-libraries"
          }}>{`Figma's swap library feature.`}</a>{`
This is an automated feature that will replace components in one version for its equivalents in
another version. Upgrading will typically not override any settings or custom text in a file, except
in the event of a breaking change, which may occur when updates are being made to a specific
component as part of a major release.`}</p>
        <p>{`Library versioning empowers teams to control when and how they upgrade to the next version of the
Canvas Web Figma library. It enables the entire team to work together using the same major versions
of the Canvas Design System. Upgrading to a new library version is quick and easy, but there are
things to consider before doing so.`}</p>
        <ol>
          <li parentName="ol">{`Locate the file you want to upgrade.`}</li>
          <li parentName="ol">{`Create a duplicate of the file or
`}<a parentName="li" {...{
              "href": "https://help.figma.com/hc/en-us/articles/360038006754-View-a-file-s-version-history"
            }}>{`save a version of if`}</a>{`.`}</li>
          <li parentName="ol">{`Open the file you wish to upgrade.`}</li>
          <li parentName="ol">{`Go to the assets panel.`}</li>
          <li parentName="ol">{`Open your team library (book icon).`}</li>
          <li parentName="ol">{`Select the current Canvas library used in the file.`}</li>
          <li parentName="ol">{`Select the ‘Swap Library’ button.`}</li>
          <li parentName="ol">{`Choose the latest library to swap to.`}</li>
          <li parentName="ol">{`Click the ‘Swap Library’ Button again.`}</li>
          <li parentName="ol">{`Wait for a confirmation from Figma.`}</li>
        </ol>
        <p>{`To confirm if the upgrade was successful, check the ‘Used in this File’ section in the files assets
panel. Canvas components from the latest version are displayed on a green background and the
component labels and descriptions will display the version number. Use these indicators to confirm
if the upgrade was successful.`}</p>
        <p>{`Libraries can be set to persist in your Figma Teams projects by an admin, this will help reduce the
number of unwanted components in a files asset panel. Contact the team's admin so the team's
preferred libraries can be used across all files. To do this, follow the steps outlined below.`}</p>
        <ol>
          <li parentName="ol">{`Click on your Team in Figma.`}</li>
          <li parentName="ol">{`Go to the Settings tab.`}</li>
          <li parentName="ol">{`Select ‘Enable Libraries for all team files’`}</li>
          <li parentName="ol">{`Turn off libraries your team isn’t building with.`}</li>
          <li parentName="ol">{`Turn on the libraries your team is building with.`}</li>
        </ol>
        <p>{`The latest version of Canvas will receive minor updates and bug-fixes to improve the version you’re
building with. Please continue to accept these updates as they will not change the version of Canvas
you’re building with.`}</p>
        <h3 {...{
          "id": "custom-component-libraries"
        }}>{`Custom Component Libraries`}</h3>
        <p>{`Canvas Libraries are not the only shared libraries available for you to use. In fact, we encourage
teams to use other team’s custom component libraries!`}</p>
        <p>{`Learn more about `}<a parentName="p" {...{
            "href": "/get-started/for-developers/extended-systems"
          }}>{`Extended Systems`}</a>{` and
`}<a parentName="p" {...{
            "href": "/get-started/for-designers/customizing-canvas#tab=custom-libraries"
          }}>{`Custom Component Libraries`}</a>{`.`}</p>
      </InternalContent>
    </TabPanel>
    <TabPanel data-id="Canvas Components" mdxType="TabPanel">
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "canvas-components"
        }}>{`Canvas Components`}</h2>
        <p>{`Canvas Components in our Figma libraries are designed, built, and QA’d to ensure flexibility and
efficiency when you are designing. Each component references Canvas Tokens and Assets and is built
using other Canvas Components when appropriate. Our mission is to provide you with high quality
flexible building blocks so you may efficiently build a diverse set of cohesive Workday experiences.`}</p>
        <h2 {...{
          "id": "identifying-canvas-components"
        }}>{`Identifying Canvas Components`}</h2>
        <p>{`The Asset Panels can feel very overwhelming when searching for what you need. To make searching and
identifying Canvas Kit Components easier, we’ve assigned a visual signifier, a Watermelon 300
background color, to help you quickly identify the most up-to-date version of that component.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "732px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4cd77055baf52c4c9dbdcb11c7731438/d0cc0/identifying-canvas-components.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "39.189189189189186%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Identifying Canvas Components",
                "title": "Identifying Canvas Components",
                "src": "/static/4cd77055baf52c4c9dbdcb11c7731438/d0cc0/identifying-canvas-components.png",
                "srcSet": ["/static/4cd77055baf52c4c9dbdcb11c7731438/1efb2/identifying-canvas-components.png 370w", "/static/4cd77055baf52c4c9dbdcb11c7731438/d0cc0/identifying-canvas-components.png 732w"],
                "sizes": "(max-width: 732px) 100vw, 732px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`We’ve also added descriptive tooltips to all our components to specify which platform they are built
for. To access this information, simply hover over the component of interest. You can also see this
information in the right-hand panel when the component is selected in your artboard.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "732px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/65af399ddd97d964752e697d7f26f609/d0cc0/component-description-labels.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "27.56756756756757%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Component Description labels",
                "title": "Component Description labels",
                "src": "/static/65af399ddd97d964752e697d7f26f609/d0cc0/component-description-labels.png",
                "srcSet": ["/static/65af399ddd97d964752e697d7f26f609/1efb2/component-description-labels.png 370w", "/static/65af399ddd97d964752e697d7f26f609/d0cc0/component-description-labels.png 732w"],
                "sizes": "(max-width: 732px) 100vw, 732px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "searching-for-components"
        }}>{`Searching for Components`}</h2>
        <p>{`Browse, search, and drag and drop components from the Assets tab.`}</p>
        <Gif mdxType="Gif">
          <p><img parentName="p" {...{
              "src": "/8f7dfee422ca7293b8f517e879112893/searching-drag-button.gif",
              "alt": "Searching figma for assets. Dragging button on to Figma artboard."
            }}></img>{`
`}<span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "732px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/6bb624ca10273f56d0ff64411d3da8c9/d0cc0/searching-drag-button.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "71.89189189189189%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgklEQVR42o2Sy0uUURjGZ99/ErSJIIUytQstWtoyqCAUahNJizSILKnoQoblIgNFk8y8ZZb1qeUwNqjTzKhjzjgz3+18l9EZyUJNI3+dPjddFD3wHF7O4f3xPOc9vuLCA+y9HWX/2fsU5++msOggRUVFFBQUcPiQrOV9Xl4++fnbk09YgriVIyUyuBkHy3VwPNlMzaSYiKeJhMOEtynf6vcVfix/58+1tubtJOd/0j29RMYyMAwT09xavui8Sbs5zmjWIDiXpsOIMjFvM+jM0CnPZ3IuQjdQNRVN07aUr2IoTHm/Qkf6E+eH/FQEe2hTIzyIDXJK6ZIOE+Rsm7TXoJNW16XKWtf/l68xEeNKqJ9OY4JHUyGqxxX6rGmeJIJUhhQCpsasEB7QETpZR8rWmZXSNoD6eu0pqiYVukSM58YnrsXeorgJHidHuB5TiGZ0bEPgWBrdfo3jtXFKHsap6Up70H+d+sp6Ipx+OorfiXK0KciOS36uBqKUjTSzr6eGXn2SOeEgpFNlTOfErRQlVSqN/Tqu2ADYlopzIxyQrqapn57g4scAb0SSOn2My4kPhGZlo7keOSMd2UmB/tki524SuV1EKQ9306SGaUjJd4t00mtN0Tj8ituvGwiZM9KJ5U3QcyOdGkLW6iZDuRIZYOeLOirDCucCrexqqebe5HuaL5zkwbE9DAcHyMxmJSDtNfx2pW0yYQ844CapTQR45yQYmE/T4kYZ+yLoc2K0GiPE5Z+05afWdG1TyF/An8srrC4swsoPxkIJnnWNylg5Iv44fT2T2CKLKQwv8raAC9++YloWS8uL3Kl/yZHSuyjBz9Te7KD0TD3j4yqua8nI2wP+AlR9qJ4MBpMBAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Searching figma for assets. Dragging button on to Figma artboard.",
                  "title": "Searching figma for assets. Dragging button on to Figma artboard.",
                  "src": "/static/6bb624ca10273f56d0ff64411d3da8c9/d0cc0/searching-drag-button.png",
                  "srcSet": ["/static/6bb624ca10273f56d0ff64411d3da8c9/1efb2/searching-drag-button.png 370w", "/static/6bb624ca10273f56d0ff64411d3da8c9/d0cc0/searching-drag-button.png 732w"],
                  "sizes": "(max-width: 732px) 100vw, 732px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Gif>
        <h2 {...{
          "id": "auto-layout-and-variants"
        }}>{`Auto Layout and Variants`}</h2>
        <p>{`Most of our components are built with auto-layout and as Variants. Selecting a component built with
Variants will show you the properties located in the right panel.`}</p>
        <h3 {...{
          "id": "auto-layout"
        }}>{`Auto Layout`}</h3>
        <Gif mdxType="Gif">
          <p><img parentName="p" {...{
              "src": "/13eae8a2bd6d4e94ef19429b25d05b34/autolayout-cards.gif",
              "alt": "Auto-layout in Figma"
            }}></img>{`
`}<span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "732px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/5516d08d9e11da7794f22c8369f5d612/d0cc0/autolayout-cards.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "72.16216216216216%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACkUlEQVR42n1TW08TYRDlJ5n4F0x4NfHdxPBqNPGSGEEUiDFSJFBIJQINBEQRRK0gCNJARUpJASkUCTcF2+3Sdu+X7na7Pc5uBUNK/ZKTmdndnO+cmdkqlmVRLBZxeihlmDSODhPY3/uJbIaDImsu5L/xBJKkEhQXgiAilUqh6ixhKaYTvxDf2ESWF+Hr8GIlGoWsqESiEClFymUiMc08CpYNu1B0yRmGOUto2wU3jg/3oPvZfUzOBNHZXI+1SAi6aZEKwYWm69AJtm2fGnMuKiMsFksfzM3MYGmsCy2Pm+Dv9IDjyHbOchXl85YLyyrAMEzkdAO5nAGekytbTu7vIByJIE9lW6sHobkgVLLpqBNFkezJbs8cQqtQQIGU8pxS2fJInw/9nlvo9ffj+dNa/IhFIel5qKpMkFxFpmmesSzwSmXLnyem8dHvQ2uzFy2edhz9ThORjfhKDrtxg/qXpwEZLjQ17yKbkcotn9zofzWGm3VNeDE0hIftPqzFt8DxPA4PMmASjm0BPNW6rv1/KCeWe7ra0HjnCjwPruJ2TTXi8RgM+9/7806FKZfi7u4ettej2FiNIrwQRjJ5DFHSaBAKREF2d052llqkXFTcmufPXezSSSQZHCZYCFJpmRVVpx5q0LQc9JwJidQIROTkzjMHIpGXKSzZLpISCcGRXkwN+BCe/4I33V6EZqdRW1ePhnt3sb0YgGVIWI+tY5P6u7wUQZJJgWWPzyGkXEpn8aizDw3Xr2FwYBD1L2fhfdKESxcvoOZyNbaCb7Ew9RptjTfwYfwTAu9HcbC3g3Q6U07o5PxxGoEYi75gDJOhZYxucPg2/xXzE+PU11V8X1rEFOVzwx30J7Xi3egwBC5LPWTxB1X897XJyjcyAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Auto-layout in Figma",
                  "title": "Auto-layout in Figma",
                  "src": "/static/5516d08d9e11da7794f22c8369f5d612/d0cc0/autolayout-cards.png",
                  "srcSet": ["/static/5516d08d9e11da7794f22c8369f5d612/1efb2/autolayout-cards.png 370w", "/static/5516d08d9e11da7794f22c8369f5d612/d0cc0/autolayout-cards.png 732w"],
                  "sizes": "(max-width: 732px) 100vw, 732px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Gif>
        <p>{`Auto Layout helps your designs in Figma to behave more like working code does. This is great when
you need to add new items in a list, accommodate longer text strings, or maintain alignment as your
designs resize. Canvas components are set up in this way, so they will adapt depending on their
size.`}</p>
        <h3 {...{
          "id": "variants"
        }}>{`Variants`}</h3>
        <Gif mdxType="Gif">
          <p><img parentName="p" {...{
              "src": "/ec8d94aa351e4db71390d6909ea93b2f/canvas-variants.gif",
              "alt": "Variants in Figma"
            }}></img>{`
`}<span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "732px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/34eb6c8c55092b2b65d4bd9dbd2a7e72/d0cc0/canvas-variants.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "56.75675675675676%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVR42p2TvWsUQRjG758QsbGwtEonCiKISAoJYmljo0JU1BAVBBHTWlmLWAhWggjCERKNuUguOVAjapGvdWdmd3ZjOL1g7m739uN+zu5e7kwjmOJh4WXmt+/7PO+UpJT8r4SwsSyLZrNJvV4niiLSNCUIAkp7AdoGmH2DdkCr1SKOY7pdSJKkAIr80G71L9u7VXQo8HyPRvMXnU6nB+wOgJl8LdnI5Ek8N7tk6srUPcUPv5CnVR/oeg5OwyIMwj4sDENKKu9CMPdR8KYmmF4ULHwWOAZmWZLZhTWm5laZrKzyvraOsFUO9H2fwIybdZimZl56I//ckEw8Vxwa1Zy873L8nsvByw6v5zUPn35g/5kyx0ZnOHLpLQdGyjx+8ZVN063juiQmCO063L09xp3xm4xfv0Jp05c8KTucfqC58KjQ8ISm8snl2atvnLj6jpGxKuduVTl1Y5aXUyu5NcpxoZvyZfk7Q2evcXj4IvuOnqeUeZX5lwWxsi5ZXpP5uNr4qM0lKRXViqY276KU8dHUbNvG8zySOGKr1WF6STBjNLmki1B2UlWqgGU/KSTyutaFikD+SrmxRdv4aFqlm0REYXuQ8gDSS/gf9RxoOtze/m2WOiY2XnbMcrfae11sM3KesnkZ2Q5mL2VnH/8AC/0KCxElUEMAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Variants in Figma",
                  "title": "Variants in Figma",
                  "src": "/static/34eb6c8c55092b2b65d4bd9dbd2a7e72/d0cc0/canvas-variants.png",
                  "srcSet": ["/static/34eb6c8c55092b2b65d4bd9dbd2a7e72/1efb2/canvas-variants.png 370w", "/static/34eb6c8c55092b2b65d4bd9dbd2a7e72/d0cc0/canvas-variants.png 732w"],
                  "sizes": "(max-width: 732px) 100vw, 732px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Gif>
        <p>{`Variants introduces a new way to group and organize variants of the same component. This reduces the
overall number of components, and makes components more intuitive to use. Canvas has many components
set up this way already, so changing the states of a button or other components can be achieved more
easily.`}</p>
        <p>{`To learn more about Auto-Layout and Variants, check out these resources from Figma
`}<a parentName="p" {...{
            "href": "https://help.figma.com/hc/en-us/articles/360040451373-Create-dynamic-designs-with-auto-layout"
          }}>{`auto-layout`}</a>{`
and `}<a parentName="p" {...{
            "href": "https://www.figma.com/best-practices/creating-and-organizing-variants/"
          }}>{`variants`}</a>{`.`}</p>
      </InternalContent>
    </TabPanel>
    <TabPanel data-id="Canvas Spaces" mdxType="TabPanel">
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "canvas-spaces"
        }}>{`Canvas Spaces`}</h2>
        <p>{`These are projects or groups of projects in Figma that help us organize Canvas resources so they're
easy to find. Canvas Web and Canvas Native Mobile work exist under the "Canvas" or "Canvas Internal"
teams.`}</p>
        <h2 {...{
          "id": "canvas-libraries-1"
        }}>{`Canvas Libraries`}</h2>
        <p>{`The Canvas libraries are the spaces to provide the designers and Product teams with all the Design
System elements that are ready to be used. If it exists in the library, it is built (code) already.`}</p>
        <h2 {...{
          "id": "canvas-specs"
        }}>{`Canvas Specs`}</h2>
        <p>{`Canvas Specs are the spaces to provide the Engineering teams with the final solutions coming from
the work done in ‘Playgrounds’.`}</p>
        <WistiaVideo id="u3hgt44hqs" mdxType="WistiaVideo" />
        <h2 {...{
          "id": "canvas-playgrounds"
        }}>{`Canvas Playgrounds`}</h2>
        <p>{`The Playground is the space to explore new ideas, design, iterate and collaborate on new or existing
Tokens, Assets, Components and Patterns. Every designer/team works in different ways. This space
doesn’t aim to change this, but to organise and facilitate the visibility of the work by creating a
single common place where to work before building and documenting.`}</p>
        <WistiaVideo id="hpy6mj7toj" mdxType="WistiaVideo" />
      </InternalContent>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      